/*
    DEMO STYLE
*/
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
:root {
  --primary-background-color: white;
  --primary-color-background: #175797;
  --primary-text-color: black;
  --sidebar-text-color: white;
  --secondary-text-color: white;
  --danger-color: #f64e60;
}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--primary-background-color)
}

.default-btn {
  background: var(--primary-color-background) !important;
  border-color: var(--primary-color-background) !important;
  color: var(--secondary-text-color) !important;
  
}

.danger-btn {
  background: var(--danger-color) !important;
  border-color: var(--danger-color) !important;
  color: var(--secondary-text-color) !important;
}


.sidebar {

  .toggle-button-nav {
    background-color: var(--primary-background-color);
    border-color: var(--primary-background-color);
    color: var(--primary-color-background);
    position:absolute;
    bottom: 10px;
    left:10px
  }

  .accordion:nth-of-type(1) {
    margin-top: 5rem;
  }

  .accordion-button:not(.collapsed) {
    color: var(--sidebar-text-color) !important;
    background-color: transparent !important;
  }
  .accordion-button {
    color: var(--sidebar-text-color) !important;
    background-color: transparent !important;
  }
  .accordion-button:focus {
    border: none;
  }
  
  .accordion-item {
    background-color: transparent !important;
  }
  
  .nav-link {
    color: var(--sidebar-text-color) !important;
  }
  
  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    filter: grayscale(100%) invert(1);
  }

  .fa-sidebar {
    font-size: 25px !important;
    padding-right: 8px;
  }
  .fa-sidebar-subitem {
    color: var(--primary-background-color); /* o el color que desees */
    margin-right: 10px; /* espacio entre el icono y el texto */
  }

  .sidebar-subitem {
    display: flex;
    align-items: center;
  }
  &.collapsed {
    .sidebar-subitem {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  &.collapsed {
    width: 80px;
    transition: width 0.2s;
    height: 100vh
  }
}



.sidebar {
  background-color: var(--primary-color-background);
  position:fixed;
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 200px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
}



.content {
    margin-left: 200px; /* Same as the width of the sidebar */
    padding: 40px;
}

.content {
  &.collapsed {
    margin-left: 60px;
    padding: 40px;
  }
}

.nav-background {
  background-color: var(--primary-background-color) !important;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
  z-index:1;
}

.table {
  .btn {
    background-color: var(--primary-color-background);
    border-color: var(--primary-color-background);
    margin-right: 5px;
  }
  th{
    cursor: pointer !important;
  }
}

table.table tr td { font-size:14px; }

.page-link { 
  color: var(--primary-color-background) !important;
}

.page-item.active .page-link { 
  background-color: var(--primary-color-background) !important;
  border-color: var(--primary-color-background) !important;
  color: var(--secondary-text-color) !important;
}


