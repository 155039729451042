.btnDanger {
    background-color: red !important;
    border-color: red !important;
}
.btnGreen {
    background-color: #81B622!important;
    border-color: #81B622!important;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    background-color: grey!important;
    border-color: grey!important;
  }

